<template>
   <main id="cuerpo">
    
    <Titular :titular_cabecero="titular"></Titular>
    <Texto :textos_contenido="textos"></Texto>

  </main><!-- fin #cuerpo --> 
</template>

<script>

import Titular from "../components/Titular.vue";
import Texto from "../components/Texto.vue";

import getTexto from "../helpers/getTextos.ts"

export default {
  data() {
    return {
      titular: 'Presentación',
      textos: []
    }
  },
  components: {
      Titular,
      Texto
  },
  methods:{                
    async loadTextoPresentacion(){
      this.textos = await getTexto("presentacion")      
    }
  },
  created(){        
      this.loadTextoPresentacion();
  }
}
</script>