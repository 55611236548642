<template>
    <main id="cuerpo">
    
        <Titular :titular_cabecero='titular'></Titular>        
        <MapaAsturias></MapaAsturias>
        <Texto :textos_contenido="textos"></Texto>
    
    </main><!-- fin #cuerpo --> 
</template>

<script>

import Titular from "../components/Titular.vue";
import MapaAsturias from "../components/Mapa.vue";
import Texto from "../components/Texto.vue";

import getTexto from "../helpers/getTextos.ts"

export default {
  data() {
    return {
      titular: 'Mapa de Fosas de Asturias',
      textos: []
    }
  },
  components: {
      Titular,
      MapaAsturias,
      Texto
  },
  methods:{                
    async loadTextoPresentacionMapa(){
      this.textos = await getTexto("mapa-fosas")      
    }
  },
  created(){        
      this.loadTextoPresentacionMapa();
  }
}
</script>