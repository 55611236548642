<template>
    <Titular :titular_cabecero='titular'></Titular>
    <Texto :textos_contenido="textos"></Texto>

    <div class="row mb-5">
        <div class="col-12 text-center">
            <router-link class="btn btn-link-mapa" to="/mapa-fosas">Ver mapa de fosas</router-link>
        </div>
    </div>
</template>

<script>

import Titular from "../components/Titular.vue";
import Texto from "../components/Texto.vue";

import getTexto from "../helpers/getTextos.ts"

export default {
  data() {
    return {
      titular: 'Descripción del Mapa de Fosas',
      textos: []
    }
  },
  components: {
      Titular,
      Texto
  },
  methods:{                
    async loadTextoPresentacionMapa(){
      this.textos = await getTexto("presentacion-mapa-fosas")      
    }
  },
  created(){        
      this.loadTextoPresentacionMapa();
  }
}
</script>