import fosasApi from "../api/fosasApi"

const getAgrupacionConcejos = async() => {   

    const concejos_agrupaciones = await getConcejosJson();    
    return (concejos_agrupaciones.data)
}

export const getFichaAgrupacionConcejos = async(link:String) => {   
        
    const agrupacion_concejo = await getConcejoJson(link);              
    return (agrupacion_concejo.data)
}

export const getConcejosJson = async() => {
    return fosasApi.get('/concejos_agrupaciones.json')                
}

export const getConcejoJson = async(link:String) => {                    
    return fosasApi.get(`/${link}.json`)                
}

export default getAgrupacionConcejos;