<template>
    <!-- ======= titular_pagina ======= -->
    <div class="titular_pagina" data-aos="fade-in">
      <div class="container">
        <h2>{{titular_cabecero}}</h2>        
      </div>
    </div><!-- End titular_pagina -->
</template>

<script>
export default {
    props:{
        titular_cabecero:{
            type: String,
            required: true,
            default: ''
        }
    },
}
</script>
