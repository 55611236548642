<template>
    <main id="cuerpo">

      <Titular :titular_cabecero='titular'></Titular>

  <!-- ======= Seccion de contacto ======= -->
  <section id="contacto" class="contacto">  

    <div class="container" data-aos="fade-up">

      <div class="row mt-5">

        <div class="col-lg-4">
          <div class="info">
            <div class="address">
              <i class="fa-solid fa-location-dot"></i>
              <h4>Localización:</h4>
              <p>Universidad de Oviedo<br>
                Departamento y el Área de Contemporánea Facultad de Filosofía y Letras <br>
                Departamento de Historia (Área de Historia Contemporánea)
              
              </p>
            
            </div>

            <div class="email">
              <i class="fa-solid fa-envelope"></i>
              <h4>Email:</h4>
              <p><a href="mailto:carmeng@uniovi.es"> carmeng@uniovi.es</a></p>
            </div>

            <div class="phone">
              <i class="fa-solid fa-phone"></i>
              <h4>Llámanos:</h4>
              <p><a href="tel:+34985104437"> 985 104 437</a></p>
            </div>

          </div>

        </div>

        <div class="col-lg-8 mt-5 mt-lg-0">

          <form role="form" class="php-email-form" novalidate="true" id="form_contacto">
            <div class="row text-danger mb-3" v-if="errors.length">
              <p><b>Por favor, revise los siguientes errores:</b></p>            
              <span v-for="error in errors" :key="error">{{ error }}</span><br>            
            </div>
            <div class="row">
              <div class="col-md-6 form-group">
                <input type="text" class="form-control" v-model="nombre" placeholder="Nombre y Apellidos *">
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" class="form-control" v-model="email" placeholder="Email *">
              </div>
            </div>
            <div class="form-group mt-3">
              <input type="text" class="form-control" v-model="telefono" placeholder="Teléfono">
            </div>
            <div class="form-group mt-3">
              <textarea class="form-control" v-model="comentario" rows="5" placeholder="Comentario *"></textarea>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="1" id="politica" v-model="politica">
              <label class="form-check-label" for="politica">
                  <a class="politica" href="https://www.uniovi.es/pol%C3%ADtica-de-privacidad" target="_blank">Acepto la política de privacidad</a>
              </label>
            </div>
            
            <div class="text-center">
              <button type="button" :disabled='loading' @click="checkForm()">
                <i v-show="loading" class="fa-solid fa-spinner fa-spin"></i>
                Enviar mensaje
              </button>              
            </div>
          </form>

        </div>

      </div>

    </div>
  </section><!-- fin contacto -->

</main><!-- fin cuerpo -->
</template>
<script>
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import Titular from "../components/Titular.vue";

export default {
    data() {
        return {
            errors: [],
            nombre: '',            
            telefono: '',
            email: '',
            comentario: '',            
            politica: 0,
            titular: 'Contacto',
            loading: false            
        };
    },    
    components: {
        Titular
    },
    methods: {
        checkForm: async function () {
            
            let bEmailValido = this.validateEmail();

            if (this.nombre && bEmailValido && this.politica) {
                let datos_contacto = {
                    nombre: this.nombre,                    
                    telefono: this.telefono,
                    email: this.email,                    
                    comentario: this.comentario
                }
                
                this.loading = true;
                this.enviaEmailConsulta(datos_contacto);                                
              }

              this.errors = [];

              if (!this.nombre) {
                this.errors.push('El nombre es obligatorio.');
              }            
              if (!bEmailValido) {
                  this.errors.push('El email es obligatorio y debe ser una dirección válida.');
              }            
              if (!this.politica) {
                  this.errors.push('Debe aceptar la política de privacidad.');
              }            
        },
        validateEmail(){
          let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          return this.email.match(validRegex);
        },
        enviaEmailConsulta(datos_contacto) {

          var self = this;
          
          let html="";
          Object.entries(datos_contacto).forEach(([key, value]) => {
            html+=`<p>${key}: ${value}</p>`            
          });

          var templateParams = {
              message: html
          };
         
          emailjs.send('service_0k2m114','template_6mzcqp1', templateParams,'rucgVjuv3hr_EI-YE')
            .then(function(response) {
              
              Swal.fire({
                title: '¡Consulta enviada!',
                text: 'Hemos recibido su consulta correctamente. Nos pondremos en contacto con usted a la mayor brevedad posible. Muchas gracias.',
                icon: 'info',
                confirmButtonText: 'Ok'
              }).then((result) => {
                self.loading = false;                
              })
                            
            }, function(err) {
              
              Swal.fire({
                title: 'Error',
                text: 'Ha ocurrido un problema al registrar su consulta. Por favor, vuelva a intentarlo. Muchas gracias.',
                icon: 'error',
                confirmButtonText: 'Ok'
              }).then((result) => {
                self.loading = false;
              })
              
              console.log('FAILED...', err);
            });                      
        }
    },
  }
  </script>