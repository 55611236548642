<template>
    <!-- ======= Seccion interior ======= -->
    <section id="interior" class="interior">
      <div class="container contenido_interior" data-aos="fade-up">

        <div class="row">          
          <div class="col-lg-12 content">
            <div v-for="texto in textos_contenido">
              <h3>{{texto.NAME}}</h3>
              <div v-html="texto.TXT"></div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- fin seccion interior -->
</template>

<script>
export default {
    props:{
        textos_contenido:{
            type: Array,
            required: true,
            default: []
        }
    },
}
</script>